export class WorahnikCustomForm {

    // messageCurrentAmount: number = 0;
    spanMessageCurrentAmount: HTMLSpanElement;

    // form: HTMLFormElement;
    inputTitleHoneyPot: HTMLInputElement;
    inputFirstName: HTMLInputElement;
    inputLastName: HTMLInputElement;
    inputCompany: HTMLInputElement;
    inputPhone: HTMLInputElement;
    inputEmail: HTMLInputElement;
    textAreaComment: HTMLTextAreaElement;
    inputPrivacyPolicy: HTMLInputElement;
    privacyIcon: HTMLDivElement;
    privacyTitle: HTMLDivElement;
    confirmButton: HTMLButtonElement;
    confirmLoader: HTMLSpanElement;
    inputLocation: HTMLInputElement;
    divErrorBox: HTMLDivElement;
    errorFirstName: HTMLParagraphElement;
    errorLastName: HTMLParagraphElement;
    errorCompany: HTMLParagraphElement;
    errorEmail: HTMLParagraphElement;
    errorNoEmail: HTMLParagraphElement
    errorComment: HTMLParagraphElement;
    errorSending: HTMLParagraphElement;
    divSuccessBox: HTMLDivElement;
    sending = false;

    constructor() {
        this.inputTitleHoneyPot = document.getElementById('titel') as HTMLInputElement;
        this.inputFirstName = document.getElementById('vorname') as HTMLInputElement;
        this.inputLastName = document.getElementById('nachname') as HTMLInputElement;
        this.inputCompany = document.getElementById('firma') as HTMLInputElement;
        this.inputPhone = document.getElementById('telefon') as HTMLInputElement;
        this.inputEmail = document.getElementById('email') as HTMLInputElement;
        this.inputPrivacyPolicy = document.getElementById('datenschutz_akzeptieren') as HTMLInputElement;
        this.privacyIcon = document.getElementById('ntw-policy-icon') as HTMLDivElement;
        this.privacyTitle = document.getElementById('ntw-policy-confirmation-title') as HTMLDivElement;
        this.confirmButton = document.getElementById('ntw-policy-confirm-button') as HTMLButtonElement;
        this.confirmLoader = document.getElementById('ntw-policy-confirming-loader') as HTMLDivElement;
        this.inputLocation = document.getElementById('location') as HTMLInputElement;

        this.errorFirstName = document.getElementById('ntw-error-first-name') as HTMLParagraphElement;
        this.errorLastName = document.getElementById('ntw-error-last-name') as HTMLParagraphElement;
        this.errorCompany = document.getElementById('ntw-error-company') as HTMLParagraphElement;
        this.errorEmail = document.getElementById('ntw-error-email') as HTMLParagraphElement;
        this.errorNoEmail = document.getElementById('ntw-error-no-email') as HTMLParagraphElement;
        this.errorComment = document.getElementById('ntw-error-comment') as HTMLParagraphElement;
        this.errorSending = document.getElementById('ntw-error-sending') as HTMLParagraphElement;

        this.divErrorBox = document.querySelector('.ntw-error-box') as HTMLDivElement;
        //TODO Bei Fehlern Errorbox anzeigen.
        this.divErrorBox.style.display = 'none';
        this.divSuccessBox = document.querySelector('.ntw-success-box') as HTMLDivElement;
        //TODO: Bei Erfolg Success Box anzeigen
        this.divSuccessBox.style.display = 'none';

        // this.form = document.getElementById('ntw-contact-form') as HTMLFormElement;
        // this.form.addEventListener('submit', (e) => {
        //     e.preventDefault();
        //     console.log('message sent');
        //     this.sendMessage();
        // })

        this.spanMessageCurrentAmount = document.getElementById('ntw_current_comment') as HTMLSpanElement;
        this.textAreaComment = document.getElementById('ntw_comment_textarea') as HTMLTextAreaElement;
        this.textAreaComment.addEventListener('input', (e) => {

            e.preventDefault();

            const currentContent = this.textAreaComment.value;
            const amount = currentContent.length;

            this.setMessageCurrentAmount(amount);
        });

        this.privacyIcon.addEventListener('click', this.onClickConfirmPolicy.bind(this));
        this.privacyTitle.addEventListener('click', this.onClickConfirmPolicy.bind(this));

        this.confirmButton.addEventListener('click', this.onClickConfirmButton.bind(this));
    }

    onClickConfirmPolicy() {
        const template = document.getElementById('ntw-wwf-policy-confirmed') as HTMLTemplateElement;
        const node = document.importNode(template.content, true);

        const templateTitle = document.getElementById('ntw-wwf-policy-title-confirmed') as HTMLTemplateElement;
        const nodeTitle = document.importNode(templateTitle.content, true);

        this.privacyIcon.innerText = '';
        this.privacyIcon.insertAdjacentElement('beforeend', node.firstElementChild!);

        this.privacyTitle.innerText = '';
        this.privacyTitle.insertAdjacentElement('beforeend', nodeTitle.firstElementChild!);

        this.privacyIcon.removeEventListener('click', this.onClickConfirmPolicy);
        this.privacyIcon.addEventListener('click', this.onClickRemoveConfirmation.bind(this));

        this.privacyTitle.removeEventListener('click', this.onClickConfirmPolicy);
        this.privacyTitle.addEventListener('click', this.onClickRemoveConfirmation.bind(this));

        this.confirmButton.classList.remove('button-atom--secondary');
        this.confirmButton.classList.remove('not-confirmed');
        this.confirmButton.classList.add('button-atom--primary');
        this.confirmButton.classList.add('confirmed');
    }

    onClickRemoveConfirmation(): void {
        const template = document.getElementById('ntw-wwf-policy-not-confirmed') as HTMLTemplateElement;
        const node = document.importNode(template.content, true);

        const templateTitle = document.getElementById('ntw-wwf-policy-title-not-confirmed') as HTMLTemplateElement;
        const nodeTitle = document.importNode(templateTitle.content, true);

        this.privacyIcon.innerText = '';
        this.privacyIcon.insertAdjacentElement('beforeend', node.firstElementChild!);

        this.privacyTitle.innerText = '';
        this.privacyTitle.insertAdjacentElement('beforeend', nodeTitle.firstElementChild!);

        this.privacyIcon.removeEventListener('click', this.onClickRemoveConfirmation);
        this.privacyIcon.addEventListener('click', this.onClickConfirmPolicy.bind(this));

        this.privacyTitle.removeEventListener('click', this.onClickRemoveConfirmation);
        this.privacyTitle.addEventListener('click', this.onClickConfirmPolicy.bind(this));

        this.confirmButton.classList.remove('button-atom--primary');
        this.confirmButton.classList.remove('confirmed');
        this.confirmButton.classList.add('button-atom--secondary');
        this.confirmButton.classList.add('not-confirmed');
    }

    onClickConfirmButton(event: Event): void {
        event.preventDefault();
        if (this.confirmButton.classList.contains('button-atom--primary') && !this.sending) {
            this.sending = true;
            this.confirmButton.classList.add('no-arrow');
            this.confirmLoader.classList.add('loader');
            this.sendMessage();
        }
    }

    setMessageCurrentAmount(amount: number) {

        this.spanMessageCurrentAmount.innerText = amount.toString();
    }

    sendMessage() {
        const url = `/?nwt_wwf_ajax=1`;
        const data = {
            type: 'send-contact',
            title: this.inputTitleHoneyPot.value,
            first_name: this.inputFirstName.value,
            last_name: this.inputLastName.value,
            company: this.inputCompany.value,
            phone: this.inputPhone.value,
            email: this.inputEmail.value,
            comment: this.textAreaComment.value,
            location: this.inputLocation.value
        };
        console.log(data);
        // console.log(url);
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "Content-Type": "application/json",
            },
        }).then(response => response.json()).then(data => {
            console.log('data',data);

            if (data.errors) {
                this.divErrorBox.style.display = 'block';
                this.divSuccessBox.style.display = 'none';
                console.log(data.errors.ntw_error_first_name);

                if (data.errors.ntw_error_first_name) {
                    this.errorFirstName.style.display = 'block';
                } else {
                    this.errorFirstName.style.display = 'none';
                }

                if (data.errors.ntw_error_last_name) {
                    this.errorLastName.style.display = 'block';
                } else {
                    this.errorLastName.style.display = 'none';
                }

                if (data.errors.ntw_error_company) {
                    this.errorCompany.style.display = 'block';
                } else {
                    this.errorCompany.style.display = 'none';
                }

                if (data.errors.ntw_error_email) {
                    this.errorEmail.style.display = 'block';
                } else {
                    this.errorEmail.style.display = 'none';
                }

                if (data.errors.ntw_error_email_no_email) {
                    this.errorNoEmail.style.display = 'block';
                } else {
                    this.errorNoEmail.style.display = 'none';
                }

                if (data.errors.ntw_error_comment) {
                    this.errorComment.style.display = 'block';
                } else {
                    this.errorComment.style.display = 'none';
                }

                if (data.errors.ntw_error_sending) {
                    this.errorSending.style.display = 'block';
                } else {
                    this.errorSending.style.display = 'none';
                }
                this.confirmButton.classList.remove('no-arrow');

            } else {
                this.divErrorBox.style.display = 'none';

                if (data.success) {
                    this.divSuccessBox.style.display = 'block';

                    this.inputFirstName.value = '';
                    this.inputLastName.value = '';
                    this.inputCompany.value = '';
                    this.inputPhone.value = '';
                    this.inputEmail.value = '';
                    this.textAreaComment.value = '';
                    this.setMessageCurrentAmount(0);
                    this.onClickRemoveConfirmation();


                    this.confirmButton.classList.add('no-arrow');

                    const template = document.getElementById('ntw-wwf-policy-sent-template') as HTMLTemplateElement;
                    const node = document.importNode(template.content, true);
                    // this.confirmLoader.innerText = '';
                    this.confirmLoader.insertAdjacentElement('beforeend', node.firstElementChild!);

                    this.confirmButton.classList.add('ntw-success');
                    const spanText = this.confirmButton.querySelector('.button-atom__text') as HTMLSpanElement;
                    spanText.innerText = 'Kontaktformular abgesendet';
                    this.inputCompany.disabled = true;
                    this.inputFirstName.disabled = true;
                    this.inputLastName.disabled = true;
                    this.inputPhone.disabled = true;
                    this.inputEmail.disabled = true;
                    this.textAreaComment.disabled = true;

                    setTimeout(() => {
                        this.inputCompany.disabled = false;
                        this.inputFirstName.disabled = false;
                        this.inputLastName.disabled = false;
                        this.inputPhone.disabled = false;
                        this.inputEmail.disabled = false;
                        this.textAreaComment.disabled = false;
                        spanText.innerText = 'Kontaktformular absenden';
                        this.confirmButton.classList.remove('ntw-success');
                        this.confirmButton.classList.remove('no-arrow');
                        this.confirmLoader.innerText = '';
                        this.divSuccessBox.style.display = 'none';
                        this.sending = false;
                    }, 5000);


                } else {
                    this.divSuccessBox.style.display = 'none';
                }

            }

            this.confirmLoader.classList.remove('loader');


        })
    }

}