import {WorahnikCustomForm} from "./form/form";

// console.log('test custom form new4');

const currentUrl = window.location.pathname;

if (currentUrl.startsWith('/kontakt/kontakt-')) {
    new WorahnikCustomForm();
}

const about_us_link = document.querySelector('#ntw_about_us_nav_link a') as HTMLAnchorElement;
const about_us_arrow = document.querySelector('#ntw_about_us_nav_link button') as HTMLAnchorElement;
const contact_link = document.querySelector('#ntw_contact_nav_link a') as HTMLAnchorElement;
const contact_arrow = document.querySelector('#ntw_contact_nav_link button') as HTMLAnchorElement;
const products_link = document.querySelector('#ntw_products_nav_link a') as HTMLAnchorElement;
const service_link = document.querySelector('#ntw_kundenservice_nav_link a') as HTMLAnchorElement;
// const currentUrl = window.location.pathname;
const currentHash = window.location.hash;
if (currentUrl.startsWith('/ueber-uns/') && ( currentUrl.length > 11 || currentHash.length > 0) ) {
    about_us_link.style.color = "#f39200";
    about_us_arrow.classList.add('ntw-active');
}
if (currentUrl.startsWith('/kontakt/') && currentUrl.length > 9) {
    contact_link.style.color = "#f39200";
    contact_arrow.classList.add('ntw-active');
}

// console.log(currentUrl);
// console.log(currentHash);


window.addEventListener('load', function () {
    if (currentUrl.startsWith('/worahnik-kundenservice/') &&  currentHash.startsWith('#lagerhaltung')) {
        service_link.style.color = "#f39200";
        const targetElement: HTMLDivElement = document.getElementById('lagerhaltung') as HTMLDivElement;
        window.scrollTo({
            top: targetElement.offsetTop - 75,
            behavior: 'smooth'
        });
    }

    if (currentUrl.startsWith('/worahnik-kundenservice/') &&  currentHash.startsWith('#lieferservice')) {
        service_link.style.color = "#f39200";
        const targetElement: HTMLDivElement = document.getElementById('lieferservice') as HTMLDivElement;
        window.scrollTo({
            top: targetElement.offsetTop - 75,
            behavior: 'smooth'
        });
    }

    if (currentUrl.startsWith('/worahnik-kundenservice/') &&  currentHash.startsWith('#spengler-express')) {
        service_link.style.color = "#f39200";
        const targetElement: HTMLDivElement = document.getElementById('spengler-express') as HTMLDivElement;
        window.scrollTo({
            top: targetElement.offsetTop - 75,
            behavior: 'smooth'
        });
    }

    if (currentUrl.startsWith('/worahnik-spenglerartikel-und-bleche/') &&  currentHash.startsWith('#partner')) {
        products_link.style.color = "#f39200";
        const targetElement: HTMLDivElement = document.getElementById('partner') as HTMLDivElement;
        window.scrollTo({
            top: targetElement.offsetTop - 125,
            behavior: 'smooth'
        });
    }

    if (currentUrl.startsWith('/ueber-uns/') &&  currentHash.startsWith('#tradition')) {
        about_us_link.style.color = "#f39200";
        about_us_arrow.classList.add('ntw-active');
        const targetElement: HTMLDivElement = document.getElementById('tradition') as HTMLDivElement;
        window.scrollTo({
            top: targetElement.offsetTop - 125,
            behavior: 'smooth'
        });
    }
})
